<template>
  <flyout :name="$props.name" :title="employeeName">
    <template #action>
      <div class="edit">
        <a v-if="getPermission('editEmployee')" class="button secondary" @click="doAction"> Edit </a>
      </div>
    </template>
    <h3>Info</h3>
    <p>{{ employeeName }}</p>
    <p>
      <a :href="mailLink">{{ employee.email }}</a>
    </p>
    <p>{{ employee.phone | formatPhone }}</p>
    <hr />
    <h3>Employee Role</h3>
    <p>{{ employeeRoleDisplay }}</p>
  </flyout>
</template>
<script>
import { mapActions } from "vuex";
import Flyout from "@/components/flyouts/Flyout";
import * as Roles from "@/security/rolesHelper";
import { mapGetters } from "vuex";
import { restApi } from "@/http/http.config";
import { encodeWithParam } from "@/util/Base64Encoding";

export default {
  name: "EmployeeFlyout",
  components: {
    Flyout,
  },
  props: {
    name: {
      type: String,
      required: true,
      default: "default",
    },
    employeeId: {
      type: String,
      required: true,
      default: "",
    },
  },
  data() {
    return {
      employee: {}
    };
  },
  watch: {
    employeeId() {
      restApi.post('employee/getEmployeeById', encodeWithParam({id: this.employeeId}))
        .then(data => {
          this.employee = data.data.result[0]
        });
    }
  },
  computed: {
    ...mapGetters([ "getPermission"]),
    employeeName() {
      return `${this.employee.firstName} ${this.employee.lastName}`;
    },
    mailLink() {
      return `mailto:${this.employee.email}`;
    },
    employeeRoleDisplay() {
      const role = this.employee.user ? this.employee.user.role : this.employee.role;
      return Roles.getDisplayValue(role);
    },
  },
  methods: {
    ...mapActions(["showFlyout"]),
    doAction() {
      this.$router.push({ name: "editEmployee", params: { id: this.employee.id } });
      this.showFlyout("");
    },
  },
};
</script>
